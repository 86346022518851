import cx from "classnames"
import * as styles from "components/Tag/TagsList.css?module"

export const TagsList = ({ children, className, ...props }) => {
  return (
    <ul className={cx(className, styles.TagsList)} {...props}>
      {children}
    </ul>
  )
}
