import PropTypes from "prop-types"
import cx from "classnames"
import { Text } from "components/Text/Text"
import TextareaAutosize from "react-autosize-textarea"
import * as React from "react"
import { findAll } from "highlight-words-core"

export const Textarea = React.forwardRef(function Textarea(props, ref) {
  const {
    disabled,
    searchWords,
    value: externalValue,
    onChange,
    initialValue,
    state = "default",
    className,
    ...rest
  } = props

  const [internalValue, setInternalValue] = React.useState(initialValue || "")

  const handleChange = (e) => {
    if (onChange) {
      return onChange(e)
    }

    setInternalValue(e.target.value)
  }

  const value = externalValue === undefined ? internalValue : externalValue

  const classNames = cx(
    "focus:outline-none text-default w-full relative bg-transparent",
    className,
  )

  return (
    <div
      className={cx(
        "relative py-3 px-4 border box-border rounded focus-within:border-primary-default focus-within:ring-4 focus-within:ring-primary-lighter bg-white",
        {
          "border-success-default": state === "success",
          "border-error-default": state === "error",
          "bg-grey-light": disabled,
          "border-grey-medium": disabled || state === "default",
        },
      )}
    >
      {searchWords ? (
        <Text
          variant="body1"
          className="absolute inset-0 py-3 px-4 text-default"
        >
          <Highlighter searchWords={searchWords} textToHighlight={value} />
        </Text>
      ) : null}
      <Text
        variant="body1"
        tag={TextareaAutosize}
        className={classNames}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        {...rest}
        ref={ref}
      />
    </div>
  )
})

Textarea.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(["default", "success", "error"]),
}

const Highlighter = ({ textToHighlight, searchWords }) => {
  const chunks = findAll({ textToHighlight, searchWords })

  return chunks.map((chunk) => {
    const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start)
    const key = `${chunk.start}-${chunk.end}`

    return (
      <HighlighterChunk key={key} text={text} highlight={chunk.highlight} />
    )
  })
}

const HighlighterChunk = ({ text, highlight }) => {
  const Component = highlight ? HighlightedChunk : NormalChunk

  const children = text.split("\n").map((line, index, arr) => (
    <React.Fragment key={index}>
      {line}
      {index !== arr.length - 1 ? <br /> : null}
    </React.Fragment>
  ))

  return <Component>{children}</Component>
}

const HighlightedChunk = ({ children }) => {
  return <mark className="bg-secondary-light">{children}</mark>
}

const NormalChunk = ({ children }) => {
  return <span>{children}</span>
}
