import PropTypes from "prop-types"
import cx from "classnames"
import { Text } from "components/Text"

export const MenuItemGroup = (props) => {
  const { title, subtitle, className, children, ...rest } = props

  return (
    <div
      className={cx(
        "space-y-2 pt-4 border-t border-grey-medium first:border-t-0",
        className,
      )}
      role="group"
      aria-label={title}
      {...rest}
    >
      <div className="text-light px-6 space-y-2">
        {title ? <Text variant="subtitle1">{title}</Text> : null}
        {subtitle ? <Text variant="caption">{subtitle}</Text> : null}
      </div>
      <div>{children}</div>
    </div>
  )
}

MenuItemGroup.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
}
