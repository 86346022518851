import { useCarousel } from "components/Carousel"
import cx from "classnames"
import { AngleRight } from "components/Icon/AngleRight"
import { AngleLeft } from "components/Icon/AngleLeft"
import { Text } from "components/Text"
import { useRef, useEffect } from "react"

/**
 * @param {CarouselProps} props
 */
export function Carousel({
  slides,
  onChange,
  className,
  components: componentsProps,
  interval,
  autoStart,
}) {
  const length = slides.length
  const { active, previous, next, handlers, style, setActive, stop } =
    useCarousel({
      length,
      interval,
      onChange,
      autoStart,
    })

  const components = { ...DEFAULT_COMPONENTS, ...componentsProps }

  const rootRef = useRef()

  useEffect(() => {
    const links = Array.from(rootRef.current?.querySelectorAll("a") ?? [])

    links.forEach((link) => link.addEventListener("click", stop))

    return () => {
      links.forEach((link) => link.removeEventListener("click", stop))
    }
  })

  if (length === 0) {
    return null
  }

  return (
    <div className={cx("relative", className)} ref={rootRef}>
      <div className={"overflow-clip"}>
        <div
          className={"grid relative"}
          {...handlers}
          style={{
            ...style,
            gridTemplateColumns: `repeat(${slides.length + 2}, 1fr)`,
          }}
        >
          {[slides[length - 1], ...slides, slides[0]].map((slide, index) => (
            <div key={index} className={"min-w-0"}>
              {slide}
            </div>
          ))}
        </div>
      </div>

      <components.ActiveSlideIndicator active={active} slides={slides} />

      {length > 1 ? (
        <>
          <components.PreviousButton onClick={previous} />
          <components.NextButton onClick={next} />
        </>
      ) : null}

      <components.BulletsContainer>
        {slides.map((_slide, index) => {
          return (
            <components.Bullet
              key={index}
              isActive={active === index}
              onClick={() => setActive(index)}
            />
          )
        })}
      </components.BulletsContainer>
    </div>
  )
}

/** @typedef {import("./useCarousel").UseCarouselProps} UseCarouselProps */

/**
 * @typedef {Object} CarouselProps
 * @property {Array<import("react").ReactElement>} slides
 * @property {UseCarouselProps["interval"]} [interval]
 * @property {UseCarouselProps["onChange"]} [onChange]
 * @property {UseCarouselProps["autoStart"]} [autoStart]
 * @property {string} [className]
 * @property {Partial<CarouselComponents>} [components]
 */

/**
 * @param {Object} props
 * @param {ControlProps["onClick"]} props.onClick
 */
const PreviousButton = ({ onClick }) => {
  return <Control onClick={onClick} position={"left"} />
}

/**
 * @param {Object} props
 * @param {ControlProps["onClick"]} props.onClick
 */
const NextButton = ({ onClick }) => {
  return <Control onClick={onClick} position={"right"} />
}

/**
 * @param {Object} props
 * @param {number} props.active
 * @param {CarouselProps["slides"]} props.slides
 */
const ActiveSlideIndicator = ({ active, slides }) => {
  return (
    <div className="absolute bottom-8 right-4 bg-current text-dark px-3 py-1 rounded-3xl">
      <Text className="text-white">
        {active + 1} / {slides.length}
      </Text>
    </div>
  )
}

/**
 * @param {Object} props
 * @param {import("react").ReactNode} props.children
 */
const BulletsContainer = ({ children: _children }) => {
  return null
}

/**
 * @param {Object} props
 * @param {boolean} props.isActive
 * @param {import("react").DOMAttributes<HTMLButtonElement>["onClick"]} props.onClick
 */
const Bullet = ({ isActive, onClick }) => {
  return (
    <button
      type={"button"}
      className={cx(
        "rounded-full w-4 h-4 bg-white transition duration-200 origin-center",
        {
          "scale-50 bg-opacity-60": !isActive,
          "scale-100": isActive,
        },
      )}
      onClick={onClick}
    ></button>
  )
}

const DEFAULT_COMPONENTS = {
  PreviousButton,
  NextButton,
  ActiveSlideIndicator,
  BulletsContainer,
  Bullet,
}

/** @typedef {typeof DEFAULT_COMPONENTS} CarouselComponents */

/**
 * @param {ControlProps} props
 */
function Control({ onClick, position }) {
  return (
    <button
      className={cx(
        {
          "lg:left-0 lg:ml-2": position === "left",
          "lg:right-0 lg:mr-2": position === "right",
        },
        "hidden lg:block lg:absolute lg:text-2xl lg:rounded-full lg:w-12 lg:h-12 lg:bg-black lg:top-1/2 lg:transform lg:-translate-y-1/2",
      )}
      type="button"
      onClick={onClick}
    >
      {position === "left" ? (
        <AngleLeft className="w-4 block ml-auto mr-auto text-white text-base" />
      ) : (
        <AngleRight className="w-4 block ml-auto mr-auto text-white text-base" />
      )}
    </button>
  )
}

/**
 * @typedef {Object} ControlProps
 * @prop {"left" | "right"} position
 * @prop {import("react").DOMAttributes<HTMLButtonElement>["onClick"]} onClick
 */
