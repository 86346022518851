import PropTypes from "prop-types"
import cx from "classnames"
import { InputBase } from "components/Input/InputBase"
import * as React from "react"

export const Input = React.forwardRef(function Input(props, ref) {
  const {
    className,
    state = "default",
    startAdornment,
    endAdornment,
    disabled,
    ...rest
  } = props

  return (
    <InputBase
      state={state}
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
    >
      <input
        className={cx(className, "w-full", "focus:outline-none leading-none")}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    </InputBase>
  )
})

Input.propTypes = {
  className: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  state: PropTypes.oneOf(["default", "success", "error"]),
  disabled: PropTypes.bool,
}
