import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { useField } from "@w3rone/json-schema-form"
import { DateSingleTextField } from "./DateSingleTextField"

export const DateIntervalField = ({ label, id, name, description, errors }) => {
  const fromField = useField(`${name}[from]`)
  const toField = useField(`${name}[to]`)

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div
        className={
          "flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2"
        }
      >
        <div className={"grow"}>
          <DateSingleTextField
            {...fromField}
            min={
              fromField.schema.options.attr.min
                ? new Date(fromField.schema.options.attr.min)
                : null
            }
            max={toField.value ? new Date(toField.value) : null}
          />
        </div>
        <div className={"grow"}>
          <DateSingleTextField
            {...toField}
            min={
              fromField.value
                ? new Date(fromField.value)
                : toField.schema.options.attr.min
                  ? new Date(toField.schema.options.attr.min)
                  : null
            }
          />
        </div>
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
