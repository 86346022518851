import * as React from "react"
import { Input } from "components/Input"
import Downshift from "downshift"
import { MapboxProvider, useGeocoding } from "components/Map/MapboxProvider"
import { useThrottleCallback } from "@react-hook/throttle"
import { FloatingMenuList } from "components/Menu"
import { MenuItem } from "components/MenuItem"
import { useTranslator } from "components/Translator"
import uniq from "lodash/uniq"
import { MapMarkerAlt } from "components/Icon"
import getCountryISO3 from "country-iso-2-to-3"
import { config } from "config"

export const MapboxAddressInput = ({
  name,
  id,
  placeholder,
  onSelectPlace,
  onChange,
  value,
  types,
}) => {
  return (
    <MapboxProvider accessToken={config.MAPBOX_ACCESS_TOKEN}>
      <AddressAutocompleteInput
        name={name}
        id={id}
        placeholder={placeholder}
        onSelectPlace={onSelectPlace}
        onChange={onChange}
        value={value}
        types={types}
      />
    </MapboxProvider>
  )
}

const AddressAutocompleteInput = ({
  name,
  id,
  placeholder,
  onSelectPlace,
  onChange,
  value,
  types,
}) => {
  const translator = useTranslator()
  const [suggestions, setSuggestions] = React.useState([])
  const geocoder = useGeocoding()
  const [referenceElement, setReferenceElement] = React.useState([])

  const languages = uniq([translator.locale, "fr"])

  const typesMapbox = types
    ?.map((type) => typesMapping.get(type))
    .filter(Boolean)

  const updateSuggestions = useThrottleCallback(async (query) => {
    if (query.trim() === "") {
      setSuggestions([])
      return
    }

    try {
      const geocodeResult = await geocoder
        .forwardGeocode({ query, language: languages, types: typesMapbox })
        .send()

      setSuggestions(geocodeResult.body.features)
    } catch (err) {
      console.error("Error while forward geocode:", err)
    }
  }, 300)

  const handleInputChange = (inputValue) => {
    if (onChange) {
      onChange(inputValue)
    }

    updateSuggestions(inputValue)
  }

  const handleChange = (feature) => {
    const place = featureToPlace(feature)
    onSelectPlace?.(place)
  }

  return (
    <Downshift
      onChange={handleChange}
      itemToString={(item) => (item ? item.place_name : "")}
      onInputValueChange={handleInputChange}
      inputValue={value}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        getRootProps,
      }) => (
        <div
          {...getRootProps(
            { className: "relative" },
            { suppressRefError: true },
          )}
          ref={setReferenceElement}
        >
          <Input
            type="text"
            {...getInputProps({
              name,
              id,
              placeholder,
            })}
            startAdornment={
              <MapMarkerAlt className="w-4 text-primary-default" />
            }
          />
          <FloatingMenuList
            {...getMenuProps()}
            referenceElement={referenceElement}
            isOpen={isOpen && suggestions.length > 0}
          >
            {suggestions.map((item, index) => (
              <MenuItem
                {...getItemProps({
                  index,
                  item,
                  focused: highlightedIndex === index,
                  label: item.place_name,
                })}
                key={item.id}
              />
            ))}
          </FloatingMenuList>
        </div>
      )}
    </Downshift>
  )
}

const typesMapping = new Map([["city", "place"]])

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.mouseUp:
      return {
        ...changes,
        inputValue: state.inputValue,
      }

    default:
      return changes
  }
}

const featureToPlace = (feature) => {
  const [featureType] = feature.id.split(".")
  const commonProperties = getFeatureCommonProperties(feature)

  const specificPropertiesGetters = {
    country: getPropertiesForCountry,
    region: getPropertiesForRegion,
    postcode: getPropertiesForPostcode,
    district: getPropertiesForDistrict,
    place: getPropertiesForPlace,
    locality: getPropertiesForLocality,
    neighborhood: getPropertiesForNeighborhood,
    address: getPropertiesForAddress,
    poi: getPropertiesForPOI,
  }

  const specificPropertiesGetter = specificPropertiesGetters[featureType]

  if (!specificPropertiesGetter) {
    throw new Error(
      `Impossible to get specific properties for the ${featureType} Mapbox Feature type`,
    )
  }
  const specificProperties = specificPropertiesGetter(feature)

  const properties = {
    ...commonProperties,
    ...specificProperties,
    country: specificProperties.country
      ? getCountryISO3(specificProperties.country.toUpperCase())
      : "",
  }

  return properties
}

const getPropertiesForCountry = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: "",
    state: "",
    city: "",
    country: feature.properties.short_code,
  }
}

const getPropertiesForRegion = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: "",
    state: feature.properties.short_code,
    city: "",
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForPostcode = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: feature.text,
    state: getPropertyFromFeatureContext(feature, "region", "short_code"),
    city: getPropertyFromFeatureContext(feature, "place", "text"),
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForDistrict = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: getPropertyFromFeatureContext(feature, "postcode", "text"),
    state: getPropertyFromFeatureContext(feature, "region", "short_code"),
    city: getPropertyFromFeatureContext(feature, "place", "text"),
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForPlace = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: getPropertyFromFeatureContext(feature, "postcode", "text"),
    state: getPropertyFromFeatureContext(feature, "region", "short_code"),
    city: feature.text,
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForLocality = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: "",
    state: getPropertyFromFeatureContext(feature, "place", "short_code"),
    city: getPropertyFromFeatureContext(feature, "place", "text"),
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForNeighborhood = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: getPropertyFromFeatureContext(feature, "postcode", "text"),
    state: getPropertyFromFeatureContext(feature, "region", "short_code"),
    city: getPropertyFromFeatureContext(feature, "place", "text"),
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForAddress = (feature) => {
  return {
    nb: feature.address,
    street: feature.text,
    zipcode: getPropertyFromFeatureContext(feature, "postcode", "text"),
    state: getPropertyFromFeatureContext(feature, "place", "short_code"),
    city: getPropertyFromFeatureContext(feature, "place", "text"),
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getPropertiesForPOI = (feature) => {
  return {
    nb: "",
    street: "",
    zipcode: getPropertyFromFeatureContext(feature, "postcode", "text"),
    state: getPropertyFromFeatureContext(feature, "region", "short_code"),
    city: getPropertyFromFeatureContext(feature, "place", "text"),
    country: getPropertyFromFeatureContext(feature, "country", "short_code"),
  }
}

const getFeatureCommonProperties = (feature) => {
  return {
    raw: feature.place_name,
    formatted: feature.place_name,
    coords: {
      lat: String(feature.center[1]),
      lng: String(feature.center[0]),
    },
  }
}

const getPropertyFromFeatureContext = (place, type, property) => {
  if (!place.context) {
    return ""
  }

  const obj = place.context.find((contextItem) => contextItem.id.includes(type))

  if (!obj) {
    return ""
  }

  return obj[property] ?? ""
}
