import cx from "classnames"
import { Text } from "components/Text"
import * as React from "react"
import { Markdown } from "components/Markdown"

export const FormControlLabel = ({ control, label, className }) => {
  return (
    <label className={cx("inline-flex space-x-2", className)}>
      <div className="pt-1">{control}</div>
      <Label>{label}</Label>
    </label>
  )
}

export const FormControlLabelCard = ({ control, label, caption }) => {
  const [checked, setChecked] = React.useState(
    control.props.defaultChecked ?? control.props.checked ?? false,
  )

  React.useEffect(() => {
    setChecked(control.props.checked)
  }, [control.props.checked])

  return (
    <label
      className={cx("flex items-center bg-white border rounded p-4 space-x-4", {
        "border-primary-default": checked,
        "border-grey-medium": !checked,
      })}
    >
      {control}
      <span className="flex flex-col">
        <Label>{label}</Label>
        {caption ? (
          <Text
            tag="span"
            variant="caption"
            className="font-bold text-primary-dark"
          >
            {caption}
          </Text>
        ) : null}
      </span>
    </label>
  )
}

const Label = ({ children }) => {
  return (
    <Markdown
      linkTarget="_blank"
      components={{
        p({ children }) {
          return (
            <Text variant="body2" tag="span">
              {children}
            </Text>
          )
        },
      }}
    >
      {children}
    </Markdown>
  )
}
