import cx from "classnames"
import { forwardRef } from "react"

export const InputBase = forwardRef(function InputBase(
  {
    state = "default",
    disabled,
    children,
    className,
    startAdornment,
    endAdornment,
    ...props
  },
  ref,
) {
  return (
    <div
      ref={ref}
      className={cx(
        className,
        "InputBase",
        {
          "border-success-default": state === "success",
          "border-error-default": state === "error",
          "bg-grey-light": disabled,
          "border-grey-medium": disabled || state === "default",
        },
        "bg-white",
        "flex",
        "flex-row",
        "space-x-2",
        "items-center",
        "justify-center",
        "w-full",
        "box-border",
        "rounded",
        "py-3",
        "px-4",
        "text-default",
        "focus-within:border-primary-default",
        "border",
        "focus-within:ring-4 focus-within:ring-primary-lighter",
        "min-h-12",
        "focus-within:z-10",
        "relative",
      )}
      {...props}
    >
      <InputAdornment state={state} disabled={disabled}>
        {startAdornment}
      </InputAdornment>
      {children}
      <InputAdornment state={state} disabled={disabled}>
        {endAdornment}
      </InputAdornment>
    </div>
  )
})

function InputAdornment({ children, state = "default", disabled, ...props }) {
  if (!children) {
    return null
  }

  return (
    <div
      className={cx({
        "text-success-default": state === "success",
        "text-error-default": state === "error",
        "text-primary-default": state === "default",
        "text-light": disabled,
      })}
      {...props}
    >
      {children}
    </div>
  )
}
