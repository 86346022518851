import cx from "classnames"
import PropTypes from "prop-types"
import { Text } from "components/Text"

export function Tag(props) {
  const {
    color = "default",
    iconStart,
    iconEnd,
    label,
    tag: Tag = "span",
    className,
    noTruncate = false,
    ...rest
  } = props

  const classNames = cx(
    "inline-flex",
    "py-1",
    "rounded-2xl",
    "flex",
    "flex-row",
    "space-x-2",
    "items-center",
    "max-w-full",
    "px-2",
    "no-underline",
    {
      "border border-grey-medium text-default": color === "default",
      "border border-grey-medium text-dark bg-grey-medium":
        color === "default-filled",
      "border border-white text-white": color === "default-contrast",
      "bg-white text-secondary-dark": color === "default-contrast-filled",
      "bg-success-lighter text-success-default": color === "success",
      "bg-primary-lighter border border-primary-light text-primary-dark":
        color === "notice",
      "bg-secondary-lighter text-secondary-dark": color === "warning",
      "bg-error-light text-error-default": color === "error",
      "truncate": !noTruncate
    },
    className,
  )

  return (
    <Tag className={classNames} {...rest}>
      {iconStart ? <span className="shrink-0">{iconStart}</span> : null}
      {label ? (
        <Text variant="tag" tag="span" className="truncate">
          {label}
        </Text>
      ) : null}
      {iconEnd ? <span className="shrink-0">{iconEnd}</span> : null}
    </Tag>
  )
}

Tag.propTypes = {
  color: PropTypes.oneOf([
    "default",
    "default-contrast",
    "default-contrast-filled",
    "default-filled",
    "success",
    "notice",
    "warning",
    "error",
  ]).isRequired,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  label: PropTypes.node.isRequired,
  tag: PropTypes.elementType,
  className: PropTypes.string,
}
