import { Input } from "components/Input"
import { MapMarkerAlt } from "components/Icon"
import getCountryISO3 from "country-iso-2-to-3"
import Downshift from "downshift"
import * as React from "react"
import { useThrottleCallback } from "@react-hook/throttle"
import { FloatingMenuList } from "components/Menu"
import { MenuItem } from "components/MenuItem"
import { config } from "config"
import { Loader } from "@googlemaps/js-api-loader"
import * as PropTypes from "prop-types"

export const GoogleMapsAddressInput = ({
  name,
  id,
  placeholder,
  onSelectPlace,
  value,
  onChange,
  types,
}) => {
  const [suggestions, setSuggestions] = React.useState([])
  const [referenceElement, setReferenceElement] = React.useState([])
  const autocompleteService = React.useRef(null)
  const geocoder = React.useRef(null)

  const typesGoogle = types
    ?.map((type) => typesMapping.get(type))
    .filter(Boolean)

  const updateSuggestions = useThrottleCallback(async (query) => {
    if (!autocompleteService.current) {
      return
    }

    if (query.trim() === "") {
      setSuggestions([])
      return
    }

    try {
      const predictionsResult =
        await autocompleteService.current.getPlacePredictions({
          input: query,
          types: typesGoogle,
        })

      setSuggestions(predictionsResult.predictions)
    } catch (err) {
      console.error("Error while forward geocode:", err)
    }
  }, 300)

  React.useEffect(() => {
    const loader = new Loader({
      apiKey: config.GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    })

    loader
      .load()
      .then((google) => {
        autocompleteService.current =
          new google.maps.places.AutocompleteService()
        geocoder.current = new google.maps.Geocoder()
      })
      .catch((err) => {
        console.error("Error while loading google maps js api:")
        console.error(err)
      })
  }, [])

  const handleInputChange = (inputValue) => {
    if (onChange) {
      onChange(inputValue)
    }

    updateSuggestions(inputValue)
  }

  const handleChange = async (selectedItem) => {
    try {
      const { results } = await geocoder.current.geocode({
        placeId: selectedItem.place_id,
      })

      if (results.length === 0) {
        console.warn("No result found for place id " + selectedItem.place_id)
        return
      }

      const place = resultToPlace(results[0])
      onSelectPlace(place)
    } catch (err) {
      console.error("Error while geocoding place id " + selectedItem.place_id)
      console.error(err)
    }
  }

  return (
    <Downshift
      onChange={handleChange}
      itemToString={(item) => (item ? item.description : "")}
      onInputValueChange={handleInputChange}
      inputValue={value}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        getRootProps,
      }) => (
        <div
          {...getRootProps(
            {
              className: "relative",
            },
            { suppressRefError: true },
          )}
          ref={setReferenceElement}
        >
          <Input
            type="text"
            {...getInputProps({
              name,
              id,
              placeholder,
            })}
            startAdornment={
              <MapMarkerAlt className="w-4 text-primary-default" />
            }
          />
          <FloatingMenuList
            {...getMenuProps()}
            referenceElement={referenceElement}
            isOpen={isOpen && suggestions.length > 0}
          >
            {suggestions.map((item, index) => (
              <MenuItem
                {...getItemProps({
                  index,
                  item,
                  focused: highlightedIndex === index,
                  label: item.description,
                })}
                key={item.id}
              />
            ))}
          </FloatingMenuList>
        </div>
      )}
    </Downshift>
  )
}

GoogleMapsAddressInput.propTypes = {
  types: PropTypes.arrayOf(PropTypes.oneOf(["city"])),
}

const typesMapping = new Map([["city", "(cities)"]])

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.mouseUp:
      return {
        ...changes,
        inputValue: state.inputValue,
      }

    default:
      return changes
  }
}

const resultToPlace = (result) => {
  const country = result.address_components.find((component) =>
    component.types.includes("country"),
  )?.short_name

  return {
    nb:
      result.address_components.find((component) =>
        component.types.includes("street_number"),
      )?.long_name || "",
    street:
      result.address_components.find((component) =>
        component.types.includes("route"),
      )?.long_name || "",
    zipcode:
      result.address_components.find((component) =>
        component.types.includes("postal_code"),
      )?.long_name || "",
    state:
      result.address_components.find((component) =>
        component.types.includes("administrative_area_level_2"),
      )?.long_name || "",
    city:
      result.address_components.find((component) =>
        component.types.includes("locality"),
      )?.long_name || "",
    country: country ? getCountryISO3(country) : "",
    raw: result.formatted_address,
    formatted: result.formatted_address,
    coords: {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
    },
  }
}
