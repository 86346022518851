import cx from "classnames"
import PropTypes from "prop-types"
import { Check } from "components/Icon/Check"

import "components/Radio/Radio.css"

export function Radio(props) {
  const { className, ...rest } = props
  const classNames = cx(
    "Radio",
    "border-2",
    "border-solid",
    "border-current",
    "rounded-full",
    "appearance-none",
    "w-4",
    "h-4",
    "outline-none",
    "relative",
    className,
  )
  return (
    <div className="relative inline-flex">
      <input className={classNames} type="radio" {...rest} />
      <Check className="pointer-events-none Radio__check" />
    </div>
  )
}

Radio.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}
