import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { useState, useRef } from "react"
import cx from "classnames"
import { Button, ButtonBase } from "components/Button"
import { useTranslator } from "components/Translator"
import PropTypes from "prop-types"
import convertAttribute from "react-attr-converter"
import mapKeys from "lodash/mapKeys"
import { Card, CardContent, CardMedia } from "components/Card"
import last from "lodash/last"
import * as React from "react"
import { Text } from "components/Text"
import {
  Pen,
  ArrowAltCircleUp,
  TrashAlt,
  EyeRegular,
  CropAlt,
  Check,
  Times,
} from "components/Icon"
import { RawLink } from "components/Link"
import { Modal, ModalActions, ModalContent } from "components/Modal"
import { Cropper } from "components/Cropper"
import noop from "lodash/noop"

export const FileField = ({
  label,
  value,
  id,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const [fileSrc, setFileSrc] = useState(value)
  const [cropData, setCropData] = useState({
    values: initialCropValues,
    preview: null,
  })

  const deleteCheckboxRef = useRef(null)

  const handleChange = (e) => {
    const [file] = e.target.files

    if (file.type.startsWith("image") || file.type.startsWith("video")) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setFileSrc(e.target.result)
        onChange?.(e.target.result)
      }

      reader.readAsDataURL(file)
    } else {
      setFileSrc(file.name)
      onChange?.(file.name)
    }
  }

  const handleDelete = () => {
    deleteCheckboxRef.current.checked = true

    setFileSrc("")
  }

  const handleApplyCrop = ({ values, preview }) => {
    setCropData({
      values: {
        w: values.width,
        h: values.height,
        x: values.x,
        y: values.y,
        r: values.rotate,
      },
      preview,
    })
  }

  const handleResetCrop = () => {
    setCropData({ values: initialCropValues, preview: null })
  }

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>
        <FileInput
          name={name + "[file]"}
          label={schema.attr?.placeholder}
          {...mapKeys(schema.attr, (_value, key) => convertAttribute(key))}
          // This attribute is not guessed from the underlying entity for now
          accept={
            name.trim().includes("image")
              ? "image/jpeg, image/png, image/gif"
              : undefined
          }
          onChange={handleChange}
          fileSrc={cropData.preview || fileSrc}
          onDelete={schema.properties.delete ? handleDelete : undefined}
          onApplyCrop={handleApplyCrop}
          onResetCrop={handleResetCrop}
        />
        <input
          name={`${name}[base64]`}
          type="hidden"
          value={
            fileSrc?.startsWith("http") ? "" : fileSrc || ""
          } /* TODO find a better way to not hydrate the input when fileSrc contains an URL */
        />
        {schema.properties.delete ? (
          <input
            className="hidden"
            name={`${name}[delete]`}
            type="checkbox"
            ref={deleteCheckboxRef}
          />
        ) : null}
        <input
          type="hidden"
          name={`${name}[h]`}
          value={cropData.values.h}
          onChange={noop}
        />
        <input
          type="hidden"
          name={`${name}[w]`}
          value={cropData.values.w}
          onChange={noop}
        />
        <input
          type="hidden"
          name={`${name}[x]`}
          value={cropData.values.x}
          onChange={noop}
        />
        <input
          type="hidden"
          name={`${name}[y]`}
          value={cropData.values.y}
          onChange={noop}
        />
        <input
          type="hidden"
          name={`${name}[r]`}
          value={cropData.values.r}
          onChange={noop}
        />
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}

const initialCropValues = {
  w: "",
  h: "",
  x: "",
  y: "",
  r: "",
}

const FileInput = ({
  label: labelProps,
  disabled,
  name,
  fileSrc,
  onChange,
  onDelete,
  onApplyCrop,
  onResetCrop,
  accept,
}) => {
  const translator = useTranslator()

  const label = fileSrc
    ? translator.trans("FileInput.update", null, "components")
    : (labelProps ??
      translator.trans("FileInput.defaultLabel", null, "components"))

  const [showCropModal, setShowCropModal] = React.useState(false)

  return (
    <>
      <div className="flex flex-col space-y-2">
        {fileSrc ? (
          <Card spacing="small">
            {isPreviewable(fileSrc) ? (
              <CardMedia
                media={
                  isImage(fileSrc) ? (
                    <RawLink href={fileSrc} external>
                      <img
                        crossOrigin="anonymous"
                        src={fileSrc}
                        className="object-contain w-full h-full"
                        loading={"lazy"}
                      />
                    </RawLink>
                  ) : isVideo(fileSrc) ? (
                    <video className="object-contain" controls>
                      <source src={fileSrc} />
                    </video>
                  ) : null
                }
                alt=""
                className="h-48"
              />
            ) : (
              <CardContent>
                <div className="flex items-center space-x-4">
                  <div>
                    <Text variant="body2">{last(fileSrc.split("/"))}</Text>
                  </div>
                  {fileSrc.startsWith("http") ? (
                    <div className="shrink-0">
                      <Button
                        href={fileSrc}
                        external
                        iconLeft={<EyeRegular className="w-4" />}
                      />
                    </div>
                  ) : null}
                </div>
              </CardContent>
            )}
          </Card>
        ) : null}
        <div className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-2">
          <div className="grow">
            <ButtonBase
              tag="label"
              className={cx({
                "cursor-default": disabled,
                "cursor-pointer": !disabled,
                "pointer-events-none": disabled,
              })}
              variant="outline"
              color="secondary"
              disabled={disabled}
              iconLeft={
                fileSrc ? (
                  <Pen className="w-4" />
                ) : (
                  <ArrowAltCircleUp className="w-4" />
                )
              }
              fullWidth
            >
              {label}
              <input
                type="file"
                className="hidden"
                name={name}
                onChange={onChange}
                accept={accept}
              />
            </ButtonBase>
          </div>
          {fileSrc && isImage(fileSrc) ? (
            <div className="grow">
              <Button
                type="button"
                fullWidth
                variant="outline"
                color="secondary"
                onClick={() => setShowCropModal(true)}
                iconLeft={<CropAlt className="w-4" />}
              >
                {translator.trans("FileInput.crop", null, "components")}
              </Button>
            </div>
          ) : null}
          {onDelete && fileSrc ? (
            <div className="grow">
              <Button
                type="button"
                variant="outline"
                color="danger"
                iconLeft={<TrashAlt className="w-4" />}
                onClick={onDelete}
                fullWidth
              >
                {translator.trans("FileInput.delete", null, "components")}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <CropperModal
        src={fileSrc}
        isOpen={showCropModal}
        onRequestClose={() => setShowCropModal(false)}
        onApply={onApplyCrop}
        onReset={onResetCrop}
      />
    </>
  )
}

FileInput.propTypes = {
  label: PropTypes.string,
}

const isPreviewable = (fileURL) => {
  return isImage(fileURL) || isVideo(fileURL)
}

/**
 * @param {string} fileURL
 * @return {string[]|null}
 */
const isImage = (fileURL) => {
  const extension = getExtension(fileURL)
  const imagesExtensions = /(jpe?g|png|gif|bmp|tiff)$/i

  return fileURL.startsWith("data:image") || extension.match(imagesExtensions)
}

/**
 * @param {string} fileURL
 * @return {string[]|null}
 */
const isVideo = (fileURL) => {
  const extension = getExtension(fileURL)
  const videosExtensions = /(avi|mov|mp4|wmv)$/i

  return fileURL.startsWith("data:video") || extension.match(videosExtensions)
}

/**
 * @param {string} fileURL
 * @return {string}
 */
const getExtension = (fileURL) => {
  return last(fileURL.split("."))
}

const CropperModal = ({ src, isOpen, onRequestClose, onApply, onReset }) => {
  const translator = useTranslator()
  const cropperRef = React.useRef(null)

  const onInitialized = (cropper) => {
    cropperRef.current = cropper
  }

  const applyCrop = () => {
    if (!cropperRef.current) {
      return
    }

    onApply({
      values: cropperRef.current.getData(),
      preview: cropperRef.current.getCroppedCanvas().toDataURL(),
    })
    onRequestClose()
  }

  const resetCrop = () => {
    if (!cropperRef.current) {
      return
    }

    cropperRef.current.reset()

    onReset()
    onRequestClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={translator.trans("FileInput.cropModal.title", null, "components")}
    >
      <ModalContent>
        <Cropper
          src={src}
          style={{ height: "55vh", width: "100%" }}
          onInitialized={onInitialized}
        />
      </ModalContent>
      <ModalActions>
        <Button
          color="danger"
          type="buton"
          onClick={resetCrop}
          iconLeft={<Times className="w-4" />}
        >
          {translator.trans("FileInput.cropModal.reset", null, "components")}
        </Button>

        <Button
          color="success"
          type="button"
          onClick={applyCrop}
          iconLeft={<Check className="w-4" />}
        >
          {translator.trans("FileInput.cropModal.apply", null, "components")}
        </Button>
      </ModalActions>
    </Modal>
  )
}
