import { ErrorsList } from "components/ErrorsList"
import { AutoField, isFormSchema } from "@w3rone/json-schema-form"
import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { InputGroup } from "components/InputGroup"
import sortBy from "lodash/sortBy"

export const ObjectField = ({ schema, name, errors, label, description }) => {
  const hasOnlyHiddenFields = Object.values(schema.properties).every(
    (field) => field.options.widget === "hidden",
  )

  const style = { display: hasOnlyHiddenFields ? "none" : undefined }

  let WidgetWrapper = DefaultWrapper

  switch (schema.options?.layout) {
    case "inline":
      WidgetWrapper = InlineWrapper
      break
  }

  const fields = sortBy(
    Object.entries(schema.properties),
    ([_key, propertySchema]) => propertySchema.options?.propertyOrder,
  )

  if (isFormSchema(schema)) {
    return (
      <div className={"space-y-1"} style={style}>
        {errors ? <ErrorsList errors={errors} /> : null}
        <WidgetWrapper>
          {fields.map(([fieldName]) => {
            return (
              <AutoField
                key={fieldName}
                name={name ? `${name}[${fieldName}]` : fieldName}
                required={schema.required.includes(fieldName)}
              />
            )
          })}
        </WidgetWrapper>
      </div>
    )
  }

  const visibleProperties = fields.filter(
    ([, schema]) => schema.options.widget !== "hidden",
  )

  const hiddenProperties = fields.filter(
    ([, schema]) => schema.options.widget === "hidden",
  )

  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label>{schema.title}</Label>
        </div>
      ) : null}
      {schema.description ? <HelpMessage>{description}</HelpMessage> : null}
      <WidgetWrapper>
        {visibleProperties.map(([key]) => (
          <AutoField key={key} name={`${name}[${key}]`} />
        ))}
      </WidgetWrapper>
      <div>
        {hiddenProperties.map(([key]) => (
          <AutoField key={key} name={`${name}[${key}]`} />
        ))}
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}

const DefaultWrapper = ({ children, style }) => {
  return (
    <div className="space-y-6" style={style}>
      {children}
    </div>
  )
}

// TODO styles of `InputGroup` are broken. It may be because it is very tied to
// DOM structure
const InlineWrapper = ({ children, style }) => {
  return (
    <div style={style}>
      <InputGroup>{children}</InputGroup>
    </div>
  )
}
