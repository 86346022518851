import sortBy from "lodash/sortBy"
import {
  FormControlLabel,
  FormControlLabelCard,
} from "components/Form/FormControlLabel"
import { Radio } from "components/Radio"
import { Select } from "components/Select"
import { Checkbox } from "components/Checkbox"
import { FormRow } from "./FormRow"
import { intersectionWith } from "lodash"

export const ChoiceField = (props) => {
  let description = props.description

  if (props.value && props.schema.options.linked_placeholders) {
    description =
      props.schema.options.linked_placeholders[props.value] ?? props.description
  }

  const Component =
    props.schema.type === "array" ? MultipleChoiceField : SimpleChoiceField

  return <Component {...props} description={description} />
}

const MultipleChoiceField = ({
  schema,
  errors,
  label,
  description,
  name,
  value,
  onChange,
  id,
}) => {
  const options = getOptions(
    schema.items.enum,
    schema.options.choice.enumTitles,
    schema.options.choice.preferredChoices,
    schema.items.options?.enum_img,
  )

  if (schema.options.choice.expanded) {
    const variant = schema.options.variant || "card"

    const FormControlType =
      variant === "card" ? FormControlLabelCard : FormControlLabel

    return (
      <FormRow label={label} id={id} description={description} errors={errors}>
        <div className="space-y-2">
          {options.map((option, index) => {
            const itemId = `${id}-${index}`

            return (
              <div key={option.value} className="space-y-1">
                <FormControlType
                  control={
                    <Checkbox
                      value={option.value}
                      id={itemId}
                      name={name + "[]"}
                      checked={value && value.includes(option.value)}
                      onChange={(e) =>
                        e.target.checked
                          ? onChange?.([...value, option.value])
                          : onChange?.(value.filter((v) => v !== option.value))
                      }
                    />
                  }
                  label={option.label}
                />
              </div>
            )
          })}
        </div>
      </FormRow>
    )
  }

  return (
    <FormRow label={label} id={id} description={description} errors={errors}>
      <Select
        isClearable={schema.options.clearable || true}
        isMulti={true}
        placeholder={schema.options.choice.placeholder}
        options={options}
        value={getSelectedOptions(options, value)}
        onChange={(selectedValues) =>
          onChange?.(selectedValues.map((selectedValue) => selectedValue.value))
        }
        name={name + "[]"}
        inputId={id}
        isSearchable={schema.options.filterable}
      />
    </FormRow>
  )
}

const SimpleChoiceField = ({
  schema,
  errors,
  label,
  description,
  name,
  value,
  onChange,
  id,
}) => {
  const options = getOptions(
    schema.enum,
    schema.options.choice.enumTitles,
    schema.options.choice.preferredChoices,
    schema.options.enum_img,
  )

  if (schema.options.choice.expanded) {
    const variant = schema.options.variant || "card"

    const FormControlType =
      variant === "card" ? FormControlLabelCard : FormControlLabel

    return (
      <FormRow label={label} id={id} description={description} errors={errors}>
        <div className="space-y-2">
          {options.map((option, index) => {
            const itemId = `${id}-${index}`

            return (
              <div key={option.value} className="space-y-1">
                <FormControlType
                  control={
                    <Radio
                      value={option.value}
                      id={itemId}
                      name={name}
                      checked={value === option.value}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  }
                  label={option.label}
                />
              </div>
            )
          })}
        </div>
      </FormRow>
    )
  }

  return (
    <FormRow label={label} id={id} description={description} errors={errors}>
      <Select
        isClearable={schema.options.clearable ?? true}
        placeholder={schema.options.choice.placeholder}
        options={options}
        value={getSelectedOptions(options, value).at(0)}
        onChange={(selectedValues) =>
          onChange(selectedValues ? selectedValues.value : null)
        }
        name={name}
        inputId={id}
        isSearchable={schema.options.filterable}
      />
    </FormRow>
  )
}

export const getOptions = (values, labels, preferredValues, images) => {
  const options = values.map((value, index) => ({
    value: String(value),
    label: String(labels[index]),
    preferred: preferredValues?.includes(value) ?? false,
    img: images?.[index] ?? undefined,
  }))

  const sortedOptions = sortBy(options, (option) => !option.preferred)

  return sortedOptions
}

const getSelectedOptions = (options, value) => {
  const values = Array.isArray(value) ? value : [value]

  const intersection = intersectionWith(
    options,
    values,
    (a, b) => a.value === String(b),
  )

  return intersection
}
