import { Input } from "components/Input"
import { Textarea } from "components/Textarea"
import mapKeys from "lodash/mapKeys"
import convertAttribute from "react-attr-converter"
import { FormRow } from "components/Form/FormRow"

export const StringField = ({
  label,
  value: valueProps = "",
  id,
  name,
  onChange,
  description,
  errors,
  schema,
  placeholder,
  defaultValue,
  readOnly = false,
}) => {
  const value = valueProps ? String(valueProps) : ""

  const attributes = mapKeys(schema.options.attr || {}, (_value, key) =>
    convertAttribute(key),
  )

  if (schema.options.widget === "hidden") {
    return <input type="hidden" name={name} id={id} value={value} />
  }

  return (
    <FormRow label={label} id={id} description={description} errors={errors}>
      {schema.options.widget === "textarea" ? (
        <Textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          name={name}
          id={id}
          searchWords={schema.options?.searchWords}
          {...attributes}
        />
      ) : (
        <Input
          type={widgetToType(schema.options.widget)}
          name={name}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          id={id}
          disabled={readOnly}
          defaultValue={defaultValue ? String(defaultValue) : undefined}
          placeholder={placeholder}
          {...attributes}
        />
      )}
    </FormRow>
  )
}

const widgetToType = (widget) => {
  const mapping = {
    integer: "number",
    range: "number",
  }

  if (mapping[widget]) {
    return mapping[widget]
  }

  if (nativeTypes.includes(widget)) {
    return widget
  }

  return "text"
}

const nativeTypes = [
  "button",
  "checkbox",
  "color",
  "date",
  "datetime",
  "datetime-local",
  "email",
  "file",
  "hidden",
  "image",
  "month",
  "number",
  "password",
  "radio",
  "range",
  "reset",
  "search",
  "submit",
  "tel",
  "text",
  "time",
  "url",
  "week",
]
