import { Label } from "components/Form/Label"
import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import { Carousel, mediaToSlide } from "components/Carousel"
import { CheckCircle } from "components/Icon"
import classNames from "classnames"

export const MediaGalleryField = ({
  label,
  value,
  id,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const slides = schema.options.medias.map((media) => {
    const rawSlide = mediaToSlide(media)
    const slideId = `${id}-${media.id}`
    const checked = value === String(media.id)

    return (
      <div key={media.id} className="relative h-full">
        {rawSlide}
        <div className="absolute inset-0">
          <input
            id={slideId}
            type="radio"
            name={name}
            value={media.id}
            onChange={(e) => onChange(e.target.value)}
            className="invisible"
          />
          <label
            htmlFor={slideId}
            className={classNames(
              "absolute inset-0 flex items-center justify-center",
              {
                "bg-black/50 cursor-default": checked,
                "cursor-pointer": !checked,
              },
            )}
          >
            <CheckCircle
              className={classNames("w-32 text-success-default", {
                visible: checked,
                invisible: !checked,
              })}
            />
          </label>
        </div>
      </div>
    )
  })
  return (
    <div className="space-y-1">
      {label ? (
        <div className="pl-4">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div>
        <Carousel slides={slides} />
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}
