import { HelpMessage } from "components/Form/HelpMessage"
import { ErrorsList } from "components/ErrorsList"
import EXIF from "exif-js"
import * as MediaType from "enums/MediaType"
import { FileField } from "./FileField"
import { AutoField } from "@w3rone/json-schema-form"
import { useCallback, useEffect } from "react"

export const MediaField = ({
  value,
  name,
  onChange,
  description,
  errors,
  schema,
}) => {
  const fileFieldName =
    value.type === MediaType.PHOTO
      ? "imageFile"
      : value.type === MediaType.VIDEO
        ? "videoFile"
        : undefined

  const handleFileChange = useCallback(
    async (e) => {
      const [file] = e.target.files

      const date = await getImageDate(file)

      const newValue = {
        ...value,
        date: date ? date.toISOString() : value?.date || "",
        type: getMediaType(file),
      }

      onChange(newValue)
    },
    [onChange, value],
  )

  useEffect(() => {
    const fileInputName = `${name}[${fileFieldName}][file]`
    const fileInput = document.querySelector(`[name="${fileInputName}"]`)

    fileInput?.addEventListener("change", handleFileChange)

    return () => {
      fileInput?.removeEventListener("change", handleFileChange)
    }
  }, [fileFieldName, name, handleFileChange])

  return (
    <div className="space-y-1">
      {description ? <HelpMessage>{description}</HelpMessage> : null}
      <div className={"space-y-4"}>
        {schema.properties.abstractFile ? (
          <FileField
            schema={schema.properties.abstractFile}
            name={`${name}[${fileFieldName}]`}
            value={value?.[fileFieldName]}
            label={schema.properties.abstractFile.title}
          />
        ) : null}
        {Object.keys(schema.properties).map((key) => {
          if (
            schema.properties.abstractFile &&
            ["abstractFile", "videoFile", "imageFile"].includes(key)
          ) {
            return null
          }

          return <AutoField key={key} name={`${name}[${key}]`} />
        })}
      </div>
      {errors ? <ErrorsList errors={errors} /> : null}
    </div>
  )
}

/**
 * @param {File} image
 * @return {Promise<any>}
 */
const getImageMetadata = async (image) => {
  return new Promise((resolve) => {
    EXIF.getData(image, function () {
      const metadata = EXIF.getAllTags(this)

      resolve(metadata)
    })
  })
}

const exifDateTimeToDate = (exifDateTime) => {
  const [date, time] = exifDateTime.split(" ")
  const [year, month, day] = date.split(":")
  const [hours, minutes, seconds] = time.split(":")

  return new Date(year, month - 1, day, hours, minutes, seconds)
}

/**
 * @param {File} image
 * @return {Promise<Date|null>}
 */
export const getImageDate = async (image) => {
  const metadata = await getImageMetadata(image)

  if (metadata.DateTimeOriginal) {
    return exifDateTimeToDate(metadata.DateTimeOriginal)
  }

  return null
}

/**
 * @param {File}
 */
export const getMediaType = (media) => {
  const [fileType] = media.type.split("/")

  switch (fileType) {
    case "image":
      return MediaType.PHOTO
    case "video":
      return MediaType.VIDEO
    default:
      throw new Error("Invalid file type")
  }
}

export const getFilePropertyFromMediaType = (mediaType) => {
  switch (mediaType) {
    case MediaType.PHOTO:
      return "imageFile"

    case MediaType.VIDEO:
      return "videoFile"

    default:
      throw new Error("Invalid media type")
  }
}
