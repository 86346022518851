import cx from "classnames"
import PropTypes from "prop-types"
import * as React from "react"

export function CardMedia(props) {
  const { className, media, title, children, ...rest } = props

  const classNames = cx("relative", "overflow-hidden", className)

  return (
    <div title={title} className={classNames} {...rest}>
      {React.cloneElement(media, {
        className: cx(
          media.props.className,
          "absolute top-0 left-0 w-full h-full object-center",
        ),
      })}
      {React.Children.count(children) > 0 ? (
        <div className="absolute top-0 left-0 w-full h-full px-4 py-2">
          {children}
        </div>
      ) : null}
    </div>
  )
}

CardMedia.propTypes = {
  className: PropTypes.string,
  media: PropTypes.element,
  title: PropTypes.string,
}
