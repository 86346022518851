import { useState } from "react"
import { Range, getTrackBackground } from "react-range"
import { Text } from "components/Text"
import PropTypes from "prop-types"
import { useTranslator } from "components/Translator"

export const InputRange = (props) => {
  const {
    initialValues,
    min,
    max,
    step = 1,
    unit,
    name,
    id,
    values: valuesProps,
    onChange,
  } = props
  const [values, setValues] = useState(initialValues ?? valuesProps)
  const isMultipleRange = values.length > 1
  const COLORS = isMultipleRange
    ? ["#BCC1CC", "#139EB3", "#BCC1CC"]
    : ["#139EB3", "#BCC1CC"]
  const translator = useTranslator()

  const handleChange = (values) => {
    setValues(values)

    if (onChange) {
      onChange(values)
    }
  }

  return (
    <div className="space-y-4">
      <Text variant="headline5" className="text-primary-default">
        {isMultipleRange
          ? translator.trans("InputRange.from", null, "components") +
            " " +
            `${getLiteralValue(values[0], unit)}` +
            " " +
            translator.trans("InputRange.to", null, "components") +
            " " +
            `${getLiteralValue(values[1], unit)}`
          : getLiteralValue(values[0], unit)}
      </Text>
      <div className="space-y-3">
        <div className="flex justify-between">
          <Text variant="caption" className="text-light">
            {min}
          </Text>
          <Text variant="caption" className="text-light">
            {max}
          </Text>
        </div>
        <Range
          name={name}
          id={id}
          step={step}
          min={min}
          max={max}
          values={values}
          onChange={handleChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className="flex h-9 w-full"
              style={props.style}
            >
              <div
                ref={props.ref}
                className="h-1 w-full rounded self-center"
                style={{
                  background: getTrackBackground({
                    values: values,
                    colors: COLORS,
                    min: min,
                    max: max,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="h-4 w-4 bg-primary-default rounded-lg"
              style={props.style}
            />
          )}
        />
      </div>
    </div>
  )
}

InputRange.propTypes = {
  initialValues: PropTypes.array.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
}

const getLiteralValue = (originalValue, unit) => {
  const isRound = Math.trunc(originalValue) === originalValue
  const value = isRound ? originalValue : originalValue.toFixed(2)

  return `${value} ${unit ?? ""}`.trim()
}
