import { config } from "config"
import { MapboxAddressInput } from "./MapboxAddressInput"
import { GoogleMapsAddressInput } from "./GoogleMapsAddressInput"

export const AddressInput = ({
  name,
  id,
  placeholder,
  onSelectPlace,
  onChange,
  value,
  types,
}) => {
  const provider = config.ADDRESS_AUTOCOMPLETE_PROVIDER

  switch (provider) {
    case "mapbox":
      return (
        <MapboxAddressInput
          name={name}
          id={id}
          placeholder={placeholder}
          onSelectPlace={onSelectPlace}
          onChange={onChange}
          value={value}
          types={types}
        />
      )

    case "googlemaps":
      return (
        <GoogleMapsAddressInput
          name={name}
          id={id}
          placeholder={placeholder}
          onSelectPlace={onSelectPlace}
          onChange={onChange}
          value={value}
          types={types}
        />
      )

    default:
      throw new Error(
        `${provider} is not a valid provider. Valid providers are ${providers.join(
          ", ",
        )}`,
      )
  }
}

const providers = ["mapbox"]
